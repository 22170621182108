export const modelDefinition = {
    modelId: 'vgw097',
    gameIds: ['drumFestival'],
    coinType1Values: [10, 20, 50, 100, 250, 500, 1000, 2500], // Gold Coin
    coinType4Values: [1, 2, 5, 10, 25, 50, 100, 250], // Sweeps Coin
    coinAmountMultiplier: 20,
    reelsLayout: [3, 3, 3, 3, 3],
    wildSymbol: 'WILD',
    scatterSymbol: 'SCAT',
    bonusSymbol: 'BONUS',

    coinType1_min_bet: 10,
    coinType1_max_bet: 2_500,
    coinType1_spin_max_bet: 2_500 * 20,
    coinType4_min_bet: 1,
    coinType4_max_bet: 250,
    coinType4_spin_max_bet: 250 * 20,

    bonusSymbolsToTriggerFreeSpins: 3,
    scatterSymbolsToTriggerProgressiveJackpots: 9,
    winTable: [
        {
            symbol: 'SCAT',
            multipliers: [2000, 500, 100, 40, 15, 5, 1],
        },
        {
            symbol: 'BONUS',
            multipliers: [2], //3x
        },
        {
            symbol: 'WILD',
            multipliers: [2500, 500, 100],
        },
        {
            symbol: 'PIC1',
            multipliers: [750, 300, 50],
        },
        {
            symbol: 'PIC2',
            multipliers: [400, 200, 25],
        },
        {
            symbol: 'PIC3',
            multipliers: [200, 150, 20],
        },
        {
            symbol: 'PIC4',
            multipliers: [150, 100, 15],
        },
        {
            symbol: 'A',
            multipliers: [40, 20, 10],
        },
        {
            symbol: 'K',
            multipliers: [40, 20, 10],
        },
        {
            symbol: 'Q',
            multipliers: [20, 10, 5],
        },
        {
            symbol: 'J',
            multipliers: [20, 10, 5],
        },
        {
            symbol: 'T',
            multipliers: [20, 10, 5],
        },
    ],

    playLines: [
        [1, 1, 1, 1, 1], // 1
        [0, 0, 0, 0, 0], // 2
        [2, 2, 2, 2, 2], // 3
        [0, 1, 2, 1, 0], // 4
        [2, 1, 0, 1, 2], // 5
        [1, 2, 1, 2, 1], // 6
        [1, 0, 1, 0, 1], // 7
        [0, 1, 0, 1, 0], // 8
        [2, 1, 2, 1, 2], // 9
        [0, 0, 1, 2, 2], // 10
        [2, 2, 1, 0, 0], // 11
        [1, 0, 0, 0, 1], // 12
        [1, 2, 2, 2, 1], // 13
        [0, 1, 1, 1, 0], // 14
        [2, 1, 1, 1, 2], // 15
        [1, 1, 0, 1, 1], // 16
        [1, 1, 2, 1, 1], // 17
        [0, 2, 0, 2, 0], // 18
        [2, 0, 2, 0, 2], // 19
        [0, 2, 2, 2, 0], // 20
    ],

    // prettier-ignore
    reels: [
        ['Q', 'PIC1', 'T', 'Q', 'T', 'A', 'A', 'PIC2', 'K', 'A', 'SCAT', 'PIC4', 'A', 'PIC3', 'PIC3', 'J', 'Q', 'WILD', 'K', 'PIC4', 'K', 'K', 'PIC3', 'Q', 'J', 'PIC1', 'Q', 'WILD', 'A', 'A', 'Q', 'J', 'PIC3', 'K', 'A', 'SCAT', 'K', 'Q', 'K', 'A', 'T', 'K', 'PIC1', 'J', 'K', 'A', 'A', 'PIC3', 'K', 'T', 'PIC4', 'PIC4', 'T', 'K', 'PIC3', 'J', 'A', 'PIC4', 'J', 'A', 'PIC4', 'T', 'K', 'Q', 'PIC2', 'Q', 'PIC4'],
        ['PIC2', 'J', 'BONUS', 'PIC1', 'K', 'K', 'WILD', 'J', 'J', 'SCAT', 'SCAT', 'Q', 'PIC4', 'K', 'Q', 'PIC2', 'PIC2', 'Q', 'PIC3', 'BONUS', 'T', 'PIC4', 'Q', 'Q', 'PIC4', 'J', 'A', 'A', 'K', 'PIC3', 'PIC3', 'A', 'K', 'PIC2', 'T', 'BONUS', 'K', 'PIC1', 'K', 'WILD', 'T', 'T', 'SCAT', 'SCAT', 'Q', 'PIC4', 'K', 'Q', 'PIC2', 'PIC4', 'Q', 'PIC3', 'BONUS', 'J', 'PIC3', 'Q', 'Q', 'PIC2', 'J', 'A', 'A', 'J', 'Q', 'J', 'Q'],
        ['BONUS', 'K', 'PIC1', 'J', 'BONUS', 'K', 'K', 'Q', 'SCAT', 'SCAT', 'SCAT', 'Q', 'PIC1', 'PIC3', 'PIC4', 'J', 'PIC4', 'A', 'A', 'PIC2', 'PIC1', 'Q', 'WILD', 'PIC4', 'PIC4', 'J', 'PIC3', 'K', 'A', 'BONUS', 'A', 'PIC2', 'PIC3', 'PIC3', 'K', 'T', 'T', 'BONUS', 'K', 'K', 'Q', 'SCAT', 'SCAT', 'SCAT', 'Q', 'PIC1', 'PIC4', 'T', 'J', 'PIC4', 'A', 'A', 'PIC2', 'PIC1', 'Q', 'WILD', 'PIC4', 'J', 'J', 'PIC3', 'K', 'A', 'BONUS', 'A', 'PIC2'],
        ['PIC1', 'K', 'BONUS', 'PIC4', 'K', 'WILD', 'PIC2', 'Q', 'J', 'SCAT', 'SCAT', 'T', 'PIC4', 'PIC4', 'J', 'Q', 'K', 'A', 'PIC3', 'A', 'PIC2', 'A', 'BONUS', 'T', 'Q', 'WILD', 'K', 'Q', 'K', 'BONUS', 'PIC3', 'A', 'PIC4', 'PIC1', 'K', 'PIC2', 'PIC1', 'PIC1', 'Q', 'PIC3', 'Q', 'T', 'SCAT', 'SCAT', 'J', 'PIC4', 'PIC4', 'T', 'Q', 'K', 'PIC1', 'PIC3', 'A', 'PIC2', 'A', 'BONUS', 'T', 'Q', 'WILD', 'K', 'Q', 'K', 'BONUS', 'PIC3', 'A'],
        ['PIC3', 'T', 'PIC2', 'K', 'K', 'PIC1', 'Q', 'PIC4', 'Q', 'K', 'J', 'SCAT', 'PIC2', 'T', 'K', 'PIC3', 'Q', 'PIC1', 'A', 'A', 'Q', 'T', 'PIC4', 'WILD', 'PIC1', 'PIC3', 'A', 'A', 'PIC2', 'Q', 'K', 'A', 'PIC4', 'T', 'A', 'WILD', 'K', 'K', 'PIC1', 'Q', 'PIC4', 'Q', 'K', 'SCAT', 'T', 'A', 'WILD', 'K', 'PIC3', 'Q', 'J', 'A', 'A', 'Q', 'J', 'PIC4', 'WILD', 'PIC1', 'PIC3', 'J', 'A', 'PIC2', 'Q', 'K', 'A', 'J', 'PIC2']
    ],

    winLevels: [
        { name: 'normal1', threshold: 2 },
        { name: 'normal2', threshold: 5 },
        { name: 'normal3', threshold: 20 },
        { name: 'bigwin', threshold: 35 },
        { name: 'megawin', threshold: 70 },
        { name: 'epicwin' },
    ],
    superDrumWeights: { chance: 1, outOf: 10 },
    superDrumMultiplierWeights: [
        { outcome: 2, weight: 133 },
        { outcome: 3, weight: 62 },
        { outcome: 4, weight: 40 },
        { outcome: 5, weight: 20 },
        { outcome: 10, weight: 5 },
        { outcome: 20, weight: 1 },
    ],
    superDrumProgressiveJackpotWeights: {
        SWEEPS: {
            20: { chance: 300, outOf: 100000 },
            40: { chance: 600, outOf: 100000 },
            100: { chance: 1500, outOf: 100000 },
            200: { chance: 3000, outOf: 100000 },
            500: { chance: 7500, outOf: 100000 },
            1000: { chance: 15000, outOf: 100000 },
            2000: { chance: 30000, outOf: 100000 },
            5000: { chance: 75000, outOf: 100000 },
        },
        GOLD: {
            200: { chance: 300, outOf: 100000 },
            400: { chance: 600, outOf: 100000 },
            1000: { chance: 1500, outOf: 100000 },
            2000: { chance: 3000, outOf: 100000 },
            5000: { chance: 7500, outOf: 100000 },
            10000: { chance: 15000, outOf: 100000 },
            20000: { chance: 30000, outOf: 100000 },
            50000: { chance: 75000, outOf: 100000 },
        },
    } as const,
    freeSpinsWheel: [10, 15, 10, 25, 10, 15, 10, 30, 15, 10, 15, 20],
    freeSpinsMultiplierWheel: [2, 3, 2, 3, 2, 4, 2, 3, 2, 3, 2, 5],

    jackpots: {
        seedValues: {
            GRAND: {
                GOLD: 200_000_000,
                SWEEPS: 20_000_000,
            },
        },
        percentToJackpotPool: {
            GRAND: 0.5,
        },
    },
};
