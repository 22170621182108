import bus from "../../../../common/bus";
import events from "../../../../common/events";
import Vue from "vue";
import { model, GameState } from "./model";
import { createChoicesFromScenario } from "./model/choices";
import {
  StepJson,
  originatorId,
} from "../../../../state/models/slots/ghost-face-lives";
import { SlotWindow } from "./slot-window";
import { LineWin } from "@vgw/gdk-math-model-tools";
import { SpinOutcome } from "./model/operations/play";
import { PickFeatureAward } from "./model/operations/shared";

export default Vue.component("Content", {
  props: ["step"],
  components: {
    "slot-window": SlotWindow,
  },
  data: function (): {
    scenario: InternalScenario;
    allSteps: Step[];
    testScenarioId: number;
  } {
    return {
      scenario: {
        reelStripPositions: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
        ],
        reelSpinDeliverySequence: "BEFORE",
        multiplierCells: [],
        singleWilds: [],
        fullReelWilds: [],
        pickFeatureAwarded: [],
      },
      allSteps: [],
      testScenarioId: 0,
    };
  },
  computed: {
    spinOutcome: function () {
      const scenario = {
        ...this.scenario,
        reelStripPositions: this.scenario.reelStripPositions.map(
          ({ value }) => value
        ),
        shouldReelSpinFeaturesApplied: true,
        reelSpinFeatureSetId: isReelSpinFeaturesNotApplicable(this.scenario)
          ? 15
          : undefined,
        deliverRemainingFeaturesBeforeReelsStop:
          this.scenario.deliverRemainingFeaturesBeforeReelsStop === "true"
            ? true
            : false,
      };
      const newGameState = this.currentStep?.gameState;
      const spinOutput = model.play(
        {
          coinType: "SC",
          gameRequest: {
            coinAmount: 1,
            pickId: 1,
          },
          gameState: newGameState,
        },
        createChoicesFromScenario(scenario)
      );

      const spinOutcomeWithWinCells = {
        ...spinOutput,
        winCells: getWinCells({
          lineWins: spinOutput.gameResponse.lineWins,
          playLines: model.definition.playLines,
        }),
      };

      return spinOutcomeWithWinCells;
    },
    currentStep: function (): Step | undefined {
      if (this.currentStepIndex === undefined) {
        return;
      }

      return this.allSteps[this.currentStepIndex];
    },
    currentStepIndex: function (): number | undefined {
      if (!this.step || !this.allSteps) return;

      return this.allSteps.findIndex(
        (s) => s.test_scenario_step_id === this.step.test_scenario_step_id
      );
    },
  },
  methods: {
    handleSingleWild(isChecked: boolean, cellIndex: number) {
      const isSelected = isChecked;
      const rowIndex = cellIndex % 3;
      const reelIndex = Math.floor(cellIndex / 3);
      if (isSelected) {
        this.scenario.singleWilds.push([rowIndex, reelIndex]);
      } else {
        const singleWildIndex = this.scenario.singleWilds.findIndex(
          (singleWild) =>
            singleWild[0] === rowIndex && singleWild[1] === reelIndex
        );
        this.scenario.singleWilds.splice(singleWildIndex, 1);
      }

      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    isSingleWildSelected(cellIndex: number): boolean {
      const rowIndex = cellIndex % 3;
      const reelIndex = Math.floor(cellIndex / 3);

      const itemIndex = (this.scenario.singleWilds ?? []).findIndex(
        (singleWild) =>
          singleWild[0] === rowIndex && singleWild[1] === reelIndex
      );
      return itemIndex > -1;
    },
    handleMultiplierSelect(
      multiplierSelectedString: string,
      cellIndex: number
    ) {
      const rowIndex = cellIndex % 3;
      const reelIndex = Math.floor(cellIndex / 3);
      const multiplierSelected = Number(multiplierSelectedString);

      if (multiplierSelected !== 1) {
        const multiplierCellIndex = this.scenario.multiplierCells?.findIndex(
          (multiplierCell) =>
            multiplierCell.cell[0] === rowIndex &&
            multiplierCell.cell[1] === reelIndex
        );

        if (multiplierCellIndex !== -1) {
          this.$set(this.scenario.multiplierCells, multiplierCellIndex, {
            ...this.scenario.multiplierCells[multiplierCellIndex],
            multiplierSelected,
          });
        } else {
          this.scenario.multiplierCells.push({
            cell: [rowIndex, reelIndex],
            multiplier: multiplierSelected,
          });
        }
      } else {
        if (this.scenario.multiplierCells !== undefined) {
          const multiplierIndex = this.scenario.multiplierCells.findIndex(
            (multiplierCell) =>
              multiplierCell.cell[0] === rowIndex &&
              multiplierCell.cell[1] === reelIndex
          );
          this.scenario.multiplierCells.splice(multiplierIndex, 1);
        }
      }
      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    isMultiplierSelected(cellIndex: number, multiplier: number): boolean {
      const rowIndex = cellIndex % 3;
      const reelIndex = Math.floor(cellIndex / 3);

      const multiplierCell = (this.scenario.multiplierCells ?? []).find(
        (multiplierCell) =>
          multiplierCell.cell[0] === rowIndex &&
          multiplierCell.cell[1] === reelIndex
      );
      return multiplierCell?.multiplier === multiplier;
    },
    getCharacterWildCombinations() {
      return model.definition.characterWildFeatureReelWeights.map(
        (c) => c.outcome
      );
    },
    getFeatureDeliveryCombinations() {
      return model.definition.reelSpinFeatureSets.map((c) => c.features);
    },
    handleCharacterWildsSelect(characterWildsSelected: string) {
      const characterWilds = characterWildsSelected.split(",") as (
        | "PIC1"
        | "PIC2"
        | "PIC3"
      )[];
      this.$set(this.scenario, "characterWilds", characterWilds);
      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    handleFeatureDeliveryOrderSelect(featureDeliveryOrderString: string) {
      const featureDeliveryOrder = featureDeliveryOrderString.split(",") as (
        | "FRW"
        | "CW"
        | "SW"
        | "M"
      )[];
      this.$set(
        this.scenario,
        "reelSpinFeatureDeliveryOrder",
        featureDeliveryOrder
      );

      bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
    isCharacterWildSelected(characterWildsSelected: string[]) {
      return (
        this.scenario.characterWilds?.join() === characterWildsSelected.join()
      );
    },
    addFeature(selectedValue: PickFeatureAward) {
      if (this.scenario.pickFeatureAwarded.length === 9) return;
      this.scenario.pickFeatureAwarded.push(selectedValue);
    },
    removeFeature(index) {
      this.scenario.pickFeatureAwarded.splice(index, 1);
    },
    moveFeatureUp(index) {
      if (index > 0) {
        if (index > 0) {
          const temp = this.scenario.pickFeatureAwarded[index];
          this.$set(
            this.scenario.pickFeatureAwarded,
            index,
            this.scenario.pickFeatureAwarded[index - 1]
          );
          this.$set(this.scenario.pickFeatureAwarded, index - 1, temp);
        }
      }
    },
    moveFeatureDown(index) {
      if (index < (this.scenario.pickFeatureAwarded ?? []).length - 1) {
        const temp = this.scenario.pickFeatureAwarded[index];
        this.$set(
          this.scenario.pickFeatureAwarded,
          index,
          this.scenario.pickFeatureAwarded[index + 1]
        );
        this.$set(this.scenario.pickFeatureAwarded, index + 1, temp);
      }
    },
    addClearingStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        gameState: "clear",
        reelStripPositions: [3, 5, 0, 0, 3],
      });
    },
    isPickSpin() {
      const gameResponse = this.spinOutcome.gameResponse;

      const isPickSpin =
        gameResponse.picks !== undefined &&
        gameResponse.picks.phase !== "START";
      return isPickSpin;
    },
    isBaseSpin() {
      return !this.isJackpotSpin() && !this.isPickSpin() && !this.isFreeSpin();
    },
    isJackpotSpin() {
      const gameResponse = this.spinOutcome.gameResponse;
      const isJackpotSpin = gameResponse.jackpotPhase !== undefined;
      return isJackpotSpin;
    },
    isFreeSpin() {
      const gameResponse = this.spinOutcome.gameResponse;
      const isFreeSpin =
        gameResponse.freeSpins && gameResponse.freeSpins.phase !== "START";
      return isFreeSpin;
    },
    shouldReelSpinFeaturesDisplayed() {
      return !this.isPickSpin() && !this.isJackpotSpin();
    },
    shouldJackpotDropDownBeDisplayed() {
      const gameResponse = this.spinOutcome.gameResponse;
      return gameResponse?.jackpotPhase === "END";
    },
    shouldFreeSpinsSelectorBeDisplayed() {
      const gameResponse = this.spinOutcome.gameResponse;

      if (!gameResponse.picks) return false;
      const { picks } = gameResponse;
      const isPickSpin = picks && picks.phase !== "START";
      const isCurrentPickIsFreeSpins = picks.currentPick?.type === "FREE_SPINS";
      return isPickSpin && isCurrentPickIsFreeSpins;
    },
    shouldPickSpinFeatureSelectorBeDisplayed() {
      const gameResponse = this.spinOutcome.gameResponse;
      if (!gameResponse.picks) return false;
      const { picks } = gameResponse;
      const isFreeSpinTrigger = picks && picks.phase === "START";
      return isFreeSpinTrigger;
    },
    addStep() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [3, 5, 0, 0, 4],
      });
    },
    addJackpotTrigger() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [3, 5, 0, 0, 3],
        jackpotAwarded: "MINI",
        reelSpinFeatureSetId: 0,
      });
    },
    addFreeSpinTrigger() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [12, 108, 50, 4, 6],
        pickFeatureAwarded: [],
      });
    },
    addLineWin() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [61, 105, 30, 0, 42],
      });
    },
    addReelSpinWin() {
      bus.$emit(events.ADD_STEP, {
        name: "Step",
        originatorId,
        reelStripPositions: [61, 105, 30, 0, 42],
        fullReelWilds: [0, 1],
        characterWilds: ["PIC1"],
      });
    },
    reloadStepsFromScenario: function (scenario: Scenario) {
      if (scenario?.test_scenario_id !== this.testScenarioId) return;
      // For each step in the reloaded scenario, if the step's most recent changes originated from edits made here,
      // then keep the local one. Otherwise, adopt the incoming step. This prevents lost updates, where slow network
      // trips result in already-outdated scenario states being sent here.
      const newSteps: Step[] = [];
      for (const incomingStep of scenario?.steps ?? []) {
        const existingStep = this.allSteps.find(
          (s) => s.test_scenario_step_id === incomingStep.test_scenario_step_id
        );
        const step =
          existingStep && incomingStep.json.originatorId === originatorId
            ? existingStep
            : incomingStep;
        newSteps.push(step);
      }
      // Traverse all steps, ensuring the state transitions are all valid and correct.
      const isModified = updateStepsInPlace(newSteps);
      this.allSteps = newSteps;
      // If any of the steps needed modifying, persist the changes.
      if (isModified) bus.$emit(events.EDIT_STEPS, this.allSteps);
    },
  },
  watch: {
    scenario: {
      handler: function (newScenario) {
        if (this.currentStepIndex === undefined) {
          return;
        }
        const convertedJson = convertToStepJson(newScenario);
        this.allSteps[this.currentStepIndex].json = {
          ...this.allSteps[this.currentStepIndex].json,
          ...convertedJson,
        };
        this.allSteps.forEach(
          (step) => (step.json.originatorId = originatorId)
        );

        updateStepsInPlace(this.allSteps);

        bus.$emit(events.EDIT_STEPS, this.allSteps);
      },
      deep: true,
    },
    step: {
      handler: function (newStep) {
        const newInternalScenario = convertStepToInternalScenario(newStep);

        if (!compareInternalScenario(newInternalScenario, this.scenario)) {
          this.scenario = newInternalScenario;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Emitted after a step is added, deleted, or edited
    bus.$on(events.UPDATE_STEPS, (scenario: Scenario) => {
      this.reloadStepsFromScenario(scenario);
    });
    // Emitted after steps are re-ordered, or the users selects a different scenario
    bus.$on(events.CHANGE_SCENARIO, (scenario: Scenario) => {
      this.testScenarioId = scenario?.test_scenario_id ?? 0;
      this.reloadStepsFromScenario(scenario);
    });
  },
});

function isReelSpinFeaturesNotApplicable(scenario: {
  fullReelWilds?: (0 | 1 | 2 | 3 | 4)[];
  singleWilds?: [number, number][];
  multiplierCells?: {
    cell: [number, number];
    multiplier: number;
  }[];
  characterWilds?: ("PIC1" | "PIC2" | "PIC3")[];
}) {
  const isFullReelWildsSelected =
    scenario.fullReelWilds !== undefined && scenario.fullReelWilds.length > 0;
  const isCharacterWildsSelected =
    scenario.characterWilds !== undefined && scenario.characterWilds.length > 0;
  const isSingleWildsSelected =
    scenario.singleWilds !== undefined && scenario.singleWilds.length > 0;
  const isMultipliersSelected =
    scenario.multiplierCells !== undefined &&
    scenario.multiplierCells.length > 0;

  return (
    isFullReelWildsSelected ||
    isCharacterWildsSelected ||
    isSingleWildsSelected ||
    isMultipliersSelected
  );
}

function compareInternalScenario(a: InternalScenario, b: InternalScenario) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function convertStepToInternalScenario(step: Step): InternalScenario {
  return {
    ...step.json,
    reelStripPositions: step.json.reelStripPositions.map((value) => ({
      value,
    })),
    deliverRemainingFeaturesBeforeReelsStop: step.json
      .deliverRemainingFeaturesBeforeReelsStop
      ? "true"
      : "false",
    multiplierCells: step.json.multiplierCells ?? [],
    singleWilds: step.json.singleWilds ?? [],
    fullReelWilds: step.json.fullReelWilds ?? [],
    reelSpinFeatureDeliveryOrder: step.json.reelSpinFeatureDeliveryOrder ?? [
      "FRW",
      "CW",
      "SW",
      "M",
    ],
    reelSpinDeliverySequence: step.json.reelSpinDeliverySequence ?? "BEFORE",
    pickFeatureAwarded: step.json.pickFeatureAwarded ?? ["START"],
  };
}

function convertToStepJson(scenario: InternalScenario): Partial<StepJson> {
  const scenarioLocal = {
    gameState: scenario.gameState,
    ...scenario,
    reelStripPositions: scenario.reelStripPositions.map(({ value }) => value),
    fullReelWilds: scenario.fullReelWilds?.map((value) => Number(value)) as (
      | 0
      | 1
      | 2
      | 3
      | 4
    )[],
    singleWilds: scenario.singleWilds,
    multiplierCells: scenario.multiplierCells,
    characterWilds: scenario.characterWilds,
    reelSpinDeliverySequence: scenario.reelSpinDeliverySequence,
    deliverRemainingFeaturesBeforeReelsStop:
      scenario.deliverRemainingFeaturesBeforeReelsStop === "true"
        ? true
        : false,
    reelSpinFeatureDeliveryOrder: scenario.reelSpinFeatureDeliveryOrder,
    pickFeatureFreeSpinsAwarded: scenario.pickFeatureFreeSpinsAwarded ?? 1,
    shouldReelSpinFeaturesApplied: true,
  };
  return scenarioLocal;
}

function updateStepsInPlace(steps: Step[]): boolean {
  let gameState: GameState | undefined = undefined;
  let modified = false;

  steps[0].gameState = undefined;
  let pickId = 1;

  for (const step of steps) {
    if (step.json.gameState === "clear") gameState = undefined;

    if (isReelSpinFeaturesNotApplicable(step.json)) {
      step.json.reelSpinFeatureSetId = 15;
    }
    step.gameState =
      gameState !== undefined
        ? JSON.parse(JSON.stringify(gameState))
        : gameState;

    const spinOutput = model.play(
      {
        coinType: "SC",
        gameRequest: {
          coinAmount: 1,
          pickId:
            gameState?.picks && gameState?.picks.phase !== "END"
              ? pickId++
              : undefined,
        },
        gameState,
      },
      createChoicesFromScenario({
        ...step.json,
      })
    );

    gameState = spinOutput.gameState;
    step.json.name = getStepName(spinOutput);
  }

  return modified;
}

function getWinCells({
  lineWins,
  playLines,
}: {
  lineWins: LineWin[];
  playLines: number[][];
}) {
  const cells: [number, number][] = [];
  for (const lineWin of lineWins) {
    const playLineIndex = lineWin.playLineIndex;
    const playLine = playLines[playLineIndex];

    for (const [reelIndex, rowIndex] of playLine.entries()) {
      cells[reelIndex] = cells[reelIndex] ?? [0, 0, 0];
      if (reelIndex < lineWin.length) {
        cells[reelIndex][rowIndex] = 1;
      }
    }
  }
  return cells;
}

interface Step {
  test_scenario_step_id: number;
  json: StepJson;
  /**
   * Game state resulting from the previous step.
   */
  gameState?: GameState;
}

interface InternalScenario {
  reelStripPositions: { value: number }[];
  fullReelWilds: (0 | 1 | 2 | 3 | 4)[];
  singleWilds: [number, number][];
  multiplierCells: {
    cell: [number, number];
    multiplier: number;
  }[];
  characterWilds?: ("PIC1" | "PIC2" | "PIC3")[];
  reelSpinDeliverySequence?: "BEFORE" | "AFTER" | "BOTH";
  deliverRemainingFeaturesBeforeReelsStop?: string;
  reelSpinFeatureDeliveryOrder?: ("FRW" | "CW" | "SW" | "M")[];
  jackpotAwarded?: "MINI" | "MINOR" | "GRAND" | "MAJOR";
  pickFeatureFreeSpinsAwarded?: number;
  pickFeatureAwarded: PickFeatureAward[];
  gameState?: "continue" | "clear";
}

type Scenario =
  | {
      steps: Step[];
      test_scenario_id: number;
    }
  | undefined;

function getStepName(spinOutput: SpinOutcome) {
  const { gameResponse } = spinOutput;

  if (gameResponse.picks?.phase === "START") return "f-trigger";
  if (gameResponse.picks && gameResponse.picks.phase !== "END")
    return "pick spin";
  if (gameResponse.picks) return "pick end";

  if (gameResponse.freeSpins && gameResponse.freeSpins.phase !== "END")
    return "free spin";

  if (gameResponse.freeSpins) return "free spin end";

  if (gameResponse.jackpotPhase === "START") return "jackpot trigger";
  if (gameResponse.jackpotPhase) return "jackpot award";
  return "base";
}
