import Vue from "vue";
import Component from "vue-class-component";
import State from "../../state/state";
import bus from "../../common/bus";
import events from "../../common/events";
import ingenuity033 from "./slots/ingenuity033/content.vue";
import VGW005 from "./slots/vgw005/content.vue";
import VGW005_P from "./slots/vgw005_p/content.vue";
import VGW008 from "./slots/vgw008/content.vue";
import VGW013 from "./slots/vgw013/content.vue";
import VGW014 from "./slots/vgw014/content.vue";
import VGW016 from "./slots/vgw016/content.vue";
import VGW017 from "./slots/vgw017/content.vue";
import VGW018 from "./slots/vgw018/content.vue";
import VGW021 from "./slots/vgw021/content.vue";
import VGW022 from "./slots/vgw022/content.vue";
import VGW024 from "./slots/vgw024/content.vue";
import VGW025 from "./slots/vgw025/content.vue";
import VGW026 from "./slots/vgw026/content.vue";
import VGW027 from "./slots/vgw027/content.vue";
import VGW028 from "./slots/vgw028/content.vue";
import VGW030 from "./slots/vgw030/content.vue";
import VGW031 from "./slots/vgw031/content.vue";
import VGW032 from "./slots/vgw032/content.vue";
import VGW033 from "./slots/vgw033/content.vue";
import VGW034 from "./slots/vgw034/content.vue";
import VGW020 from "./slots/vgw020/content.vue";
import VGW035 from "./slots/vgw035/content.vue";
import VGW036 from "./slots/vgw036/content.vue";
import VGW040 from "./slots/vgw040/content.vue";
import VGW041 from "./slots/vgw041/content.vue";
import VGW042 from "./slots/vgw042/content.vue";
import VGW044 from "./slots/vgw044/content.vue";
import VGW045 from "./slots/vgw045/content.vue";
import VGW046 from "./slots/vgw046/content.vue";
import VGW047 from "./slots/vgw047/content.vue";
import VGW048 from "./slots/vgw048/content.vue";
import VGW050 from "./slots/vgw050/content.vue";
import VGW051 from "./slots/vgw051/content.vue";
import VGW053 from "./slots/vgw053/content.vue";
import VGW054 from "./slots/vgw054/content.vue";
import VGW056 from "./slots/vgw056/content.vue";
import VGW057 from "./slots/vgw057/content.vue";
import VGW060 from "./slots/vgw060/content.vue";
import VGW064 from "./slots/vgw064/content.vue";
import VGW065 from "./slots/vgw065/content.vue";
import VGW066 from "./slots/vgw066/content.vue";
import VGW067 from "./slots/vgw067/content.vue";
import VGW071 from "./slots/vgw071/content.vue";
import VGW070 from "./slots/vgw070/content.vue";
import VGW073 from "./slots/vgw073/content.vue";
import VGW075 from "./slots/vgw075/content.vue";
import VGW076 from "./slots/vgw076/content.vue";
import VGW077 from "./slots/vgw077/content.vue";
import VGW078 from "./slots/vgw078/content.vue";
import VGW080 from "./slots/vgw080/content.vue";
import VGW082 from "./slots/vgw082/content.vue";
import VGW086 from "./slots/vgw086/content.vue";
import VGW088 from "./slots/vgw088/content.vue";
import VGW089 from "./slots/vgw089/content.vue";
import VGW091 from "./slots/vgw091/content.vue";
import VGW092 from "./slots/vgw092/content.vue";
import VGW096 from "./slots/vgw096/content.vue";
import VGW097 from "./slots/vgw097/content.vue";
import VGW099 from "./slots/vgw099/content.vue";
import starCatchJackpots from "./slots/starCatchJackpots/content.vue"
import bigBoss from "./slots/bigBoss/content.vue";
import DRAGONS_DEN from "./slots/dragonsden/content.vue";
import MAYAN_GOLD from "./slots/mayangold/content.vue";
import MAUI_MAGIC from "./slots/mauimagic/content.vue";
import VALKYRIE from "./slots/valkyrie/content.vue";
import AMAZONIA from "./slots/amazonia/content.vue";
import CRAZY_BUNNY from "./slots/crazybunny/content.vue";
import FLAMBINGO from "./bingo/flambingo/content.vue";
import PF002_SAMUREEL from "./slots/samureel/content.vue";
import PF003_MONSOON from "./slots/pf003_monsoon/content.vue";
import PUGROYALE from "./slots/pf005_pugroyale/content.vue";
import PF006_FISHANDCHIPS from "./slots/pf006_fishandchips/content.vue";
import HANSELANDGRETEL from "./slots/hanselandgretel/content.vue";
import VGW037 from "./slots/vgw037/content.vue";
import PF007_VIKINGODYSSEY from "./slots/pf007_vikingodyssey/content.vue";
import SPELLOFODIN from "./slots/tf001_spellofodin/content.vue";
import CELTICGODDESS from "./slots/tf002_celticgoddess/content.vue";
import REELBERRYBLAST from "./slots/pf008_reelberryblast/content.vue";
import MEDUSAGOLDENGAZE from "./slots/tf003_medusasgoldengaze/content.vue";
import SQUISH from "./slots/rv001_squish/content.vue";
import GUSTAVOELLUCHADOR from "./slots/pf010_gustavoelluchador/content.vue";
import OUTERSPACEINVADERS from "./slots/pf009_outerspaceinvaders/content.vue";
import SANDPRINCESS from "./slots/tf004_sandprincess/content.vue";
import VOLATILEGRS from "./slots/grs001_volatile/content.vue";
import EUROPEANROULETTE from "./roulettes/rl001_europeanroulette/content.vue";
import BACKBLACKJACK from "./cards/backblackjack/content.vue";
import AMERICANROULETTE from "./roulettes/rl002_americanroulette/content.vue";
import BUSTERHAMMERCARNIVAL from "./slots/rp001_busterHammerCarnival/content.vue";
import LEGENDOF9SUNS from "./slots/tf005_legendof9suns/content.vue";
import HYPERNOVAMEGAWAYS from "./slots/rp002_hypernovaMegaways/content.vue";
import AMERICANROULETTEX2 from "./roulettes/rl003_americanroulettex2/content.vue";
import ELDORADO from "./slots/rp003_eldorado/content.vue";
import EUROPEANROULETTEX2 from "./roulettes/rl004_europeanroulettex2/content.vue";
import ingenuity031 from "./slots/ingenuity031/content.vue";
import ingenuity032 from "./slots/ingenuity032/content.vue";
import CLASSICBLACKJACK from "./cards/tf102/content.vue";
import ingenuity034 from "./slots/ingenuity034/content.vue";
import tf101 from "./slots/tf101/content.vue";
import tf103 from "./slots/tf103/content.vue";
import ingenuity036 from "./slots/ingenuity036/content.vue";
import ingenuity037 from "./slots/ingenuity037/content.vue";
import tf104 from "./slots/tf104/content.vue";
import tf105 from "./slots/tf105/content.vue";
import tf106 from "./slots/tf106/content.vue";
import tf107 from "./slots/tf107/content.vue";
import tf108 from "./slots/tf108/content.vue";
import tf109 from "./slots/tf109/content.vue";
import tf110 from "./slots/tf110/content.vue";
import tf111 from "./slots/tf111/content.vue";
import tf112 from "./slots/tf112/content.vue";
import tf113 from "./slots/tf113/content.vue";
import tf114 from "./slots/tf114/content.vue";
import tf116 from "./slots/tf116/content.vue";
import tf115 from "./slots/tf115/content.vue";
import tf117 from "./slots/tf117/content.vue";
import tf118 from "./dice/tf118/content.vue";
import tf119 from "./slots/tf119/content.vue";
import tf120 from "./slots/tf120/content.vue";
import tf121 from "./slots/tf121/content.vue";
import tf122 from "./slots/tf122/content.vue";
import tf123 from "./slots/tf123/content.vue";
import tf124 from "./slots/tf124/content.vue";
import tf125 from "./roulettes/tf125_amroulette/content.vue";
import tf126 from "./slots/tf126/content.vue";
import tf128 from "./slots/tf128/content.vue";

@Component({
  components: {
    "sm-ingenuity032": ingenuity032,
    "sm-ingenuity033": ingenuity033,
    "sm-vgw005": VGW005,
    "sm-vgw005_p": VGW005_P,
    "sm-vgw008": VGW008,
    "sm-vgw013": VGW013,
    "sm-vgw014": VGW014,
    "sm-vgw016": VGW016,
    "sm-vgw017": VGW017,
    "sm-vgw018": VGW018,
    "sm-vgw021": VGW021,
    "sm-vgw022": VGW022,
    "sm-vgw024": VGW024,
    "sm-vgw025": VGW025,
    "sm-vgw026": VGW026,
    "sm-vgw027": VGW027,
    "sm-vgw028": VGW028,
    "sm-vgw030": VGW030,
    "sm-vgw031": VGW031,
    "sm-vgw032": VGW032,
    "sm-vgw033": VGW033,
    "sm-vgw034": VGW034,
    "sm-vgw035": VGW035,
    "sm-vgw036": VGW036,
    "sm-vgw040": VGW040,
    "sm-vgw041": VGW041,
    "sm-vgw042": VGW042,
    "sm-vgw044": VGW044,
    "sm-vgw045": VGW045,
    "sm-vgw046": VGW046,
    "sm-vgw047": VGW047,
    "sm-vgw048": VGW048,
    "sm-vgw050": VGW050,
    "sm-vgw051": VGW051,
    "sm-vgw053": VGW053,
    "sm-vgw054": VGW054,
    "sm-vgw056": VGW056,
    "sm-vgw057": VGW057,
    "sm-vgw060": VGW060,
    "sm-vgw064": VGW064,
    "sm-vgw065": VGW065,
    "sm-vgw066": VGW066,
    "sm-vgw067": VGW067,
    "sm-vgw071": VGW071,
    "sm-vgw070": VGW070,
    "sm-vgw073": VGW073,
    "sm-vgw075": VGW075,
    "sm-vgw076": VGW076,
    "sm-vgw077": VGW077,
    "sm-vgw078": VGW078,
    "sm-vgw080": VGW080,
    "sm-vgw082": VGW082,
    "sm-vgw086": VGW086,
    "sm-vgw088": VGW088,
    "sm-vgw089": VGW089,
    "sm-vgw091": VGW091,
    "sm-vgw092": VGW092,
    "sm-vgw096": VGW096,
    "sm-vgw097": VGW097,
    "sm-vgw099": VGW099,
    "sm-starcatchjackpots": starCatchJackpots,
    "sm-dragonsden": DRAGONS_DEN,
    "sm-mayangold": MAYAN_GOLD,
    "sm-mauimagic": MAUI_MAGIC,
    "sm-vgw_2by2_002": VALKYRIE,
    "sm-vgw020": VGW020,
    "sm-amazonia": AMAZONIA,
    "sm-crazybunny": CRAZY_BUNNY,
    "sm-pf002_samureel": PF002_SAMUREEL,
    "sm-pf003_monsoon": PF003_MONSOON,
    "sm-flambingo": FLAMBINGO,
    "sm-pf005_pugroyale": PUGROYALE,
    "sm-pf006_fishandchips": PF006_FISHANDCHIPS,
    "sm-pf004_hanselgretel": HANSELANDGRETEL,
    "sm-vgw037": VGW037,
    "sm-pf007_vikingodyssey": PF007_VIKINGODYSSEY,
    "sm-tf001_spellofodin": SPELLOFODIN,
    "sm-tf002_celticgoddess": CELTICGODDESS,
    "sm-pf008_reelberryblast": REELBERRYBLAST,
    "sm-tf003_medusasgoldengaze": MEDUSAGOLDENGAZE,
    "sm-pf009_outerspaceinvaders": OUTERSPACEINVADERS,
    "sm-rv001_squish": SQUISH,
    "sm-pf010_gustavoelluchador": GUSTAVOELLUCHADOR,
    "sm-vladimirfarm": bigBoss,
    "sm-tf004_sandprincess": SANDPRINCESS,
    "sm-rl001_europeanroulette": EUROPEANROULETTE,
    "sm-rl002_americanroulette": AMERICANROULETTE,
    "sm-grs001_volatile": VOLATILEGRS,
    "sm-backblackjack": BACKBLACKJACK,
    "sm-rp001_busterhammercarnival": BUSTERHAMMERCARNIVAL,
    "sm-tf005_legendof9suns": LEGENDOF9SUNS,
    "sm-rp002_hypernovamegaways": HYPERNOVAMEGAWAYS,
    "sm-rl003_americanroulettex2": AMERICANROULETTEX2,
    "sm-rp003_eldorado": ELDORADO,
    "sm-rl004_europeanroulettex2": EUROPEANROULETTEX2,
    "sm-ingenuity031": ingenuity031,
    "sm-tf102": CLASSICBLACKJACK,
    "sm-ingenuity034": ingenuity034,
    "sm-tf101": tf101,
    "sm-tf103": tf103,
    "sm-ingenuity036": ingenuity036,
    "sm-ingenuity037": ingenuity037,
    "sm-tf104": tf104,
    "sm-tf105": tf105,
    "sm-tf106": tf106,
    "sm-tf107": tf107,
    "sm-tf108": tf108,
    "sm-tf109": tf109,
    "sm-tf110": tf110,
    "sm-tf111": tf111,
    "sm-tf112": tf112,
    "sm-tf113": tf113,
    "sm-tf114": tf114,
    "sm-tf116": tf116,
    "sm-tf115": tf115,
    "sm-tf117": tf117,
    "sm-tf118": tf118,
    "sm-tf119": tf119,
    "sm-tf120": tf120,
    "sm-tf121": tf121,
    "sm-tf122": tf122,
    "sm-tf123": tf123,
    "sm-tf124": tf124,
    "sm-tf125": tf125,
    "sm-tf126": tf126,
    "sm-tf128": tf128,
  },
  data: () => {
    return {
      selectedStep: null,
      gameComponent: null,
    };
  },
})
export default class GameContent extends Vue {
  public selectedStep: any = {};
  public state: any;
  private game: any;
  public gameComponent: any;
  constructor() {
    super();
    bus.$on(events.MODEL_CHANGED, () => this.changeModel());
    bus.$on(events.CHANGE_STEP, (step) => this.changeStep(step));
    this.state = State.state;
  }
  public changeModel() {
    if (!this.state.getModelId()) {
      return;
    }
    this.gameComponent = "sm-" + this.state.getModelId().toLowerCase();
  }
  public changeStep(step) {
    // this.$refs.divGameContent["scrollTop"] = 0;
    // trace(JSON.parse(JSON.stringify(step.json.symbolInfos)));
    // trace(step.json.symbolInfos[1][3][0].locked)
    if (!step) {
      this.selectedStep = null;
      return;
    }
    this.selectedStep = step;
  }
}
