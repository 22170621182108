export const modelDefinition = {
    // prettier-ignore
    reels: [
        ['PIC5', 'HEART', 'BONUS', 'PIC4', 'PIC4', 'HEART', 'PIC3', 'PIC5', 'CLUB', 'DIAMOND', 'DIAMOND', 'PIC3', 'CLUB', 'DIAMOND', 'CLUB', 'SPADE', 'SPADE', 'HEART', 'BONUS', 'PIC5', 'PIC5', 'PIC3', 'PIC3', 'CLUB', 'PIC2', 'CLUB', 'PIC4', 'DIAMOND', 'CLUB', 'PIC3', 'CLUB', 'PIC5', 'BONUS', 'PIC3', 'SPADE', 'SPADE', 'HEART', 'PIC2', 'HEART', 'PIC3', 'PIC5', 'PIC4', 'SPADE', 'SPADE', 'SCAT', 'SPADE', 'SPADE', 'PIC1', 'PIC1', 'PIC2', 'PIC2', 'HEART', 'SPADE', 'HEART', 'PIC3', 'HEART', 'PIC3', 'PIC5', 'PIC1', 'PIC4', 'PIC4', 'SCAT', 'PIC1', 'SPADE', 'SPADE'],
        ['CLUB', 'PIC2', 'BONUS', 'PIC5', 'SPADE', 'SPADE', 'PIC5', 'PIC2', 'DIAMOND', 'DIAMOND', 'DIAMOND', 'HEART', 'HEART', 'SPADE', 'PIC4', 'SPADE', 'PIC5', 'HEART', 'PIC4', 'DIAMOND', 'WILD', 'PIC5', 'DIAMOND', 'PIC5', 'CLUB', 'PIC3', 'WILD', 'DIAMOND', 'PIC1', 'CLUB', 'CLUB', 'PIC2', 'PIC2', 'HEART', 'DIAMOND', 'PIC4', 'PIC4', 'DIAMOND', 'DIAMOND', 'CLUB', 'SCAT', 'SCAT', 'CLUB', 'PIC5', 'HEART', 'HEART', 'SPADE', 'SPADE', 'PIC1', 'DIAMOND', 'PIC1', 'PIC2', 'DIAMOND', 'BONUS', 'DIAMOND', 'PIC1', 'PIC2', 'SCAT', 'SCAT', 'PIC1', 'CLUB', 'PIC4', 'HEART', 'CLUB', 'PIC1'],
        ['PIC3', 'PIC3', 'PIC4', 'CLUB', 'BONUS', 'HEART', 'PIC4', 'PIC5', 'PIC1', 'HEART', 'WILD', 'DIAMOND', 'DIAMOND', 'PIC5', 'HEART', 'DIAMOND', 'HEART', 'HEART', 'CLUB', 'CLUB', 'CLUB', 'DIAMOND', 'SCAT', 'SCAT', 'SCAT', 'PIC4', 'CLUB', 'HEART', 'PIC1', 'BONUS', 'PIC4', 'SPADE', 'DIAMOND', 'SPADE', 'PIC2', 'CLUB', 'SPADE', 'PIC3', 'WILD', 'PIC4', 'HEART', 'CLUB', 'HEART', 'PIC2', 'SPADE', 'PIC3', 'PIC3', 'WILD', 'CLUB', 'SPADE', 'PIC3', 'DIAMOND', 'PIC4', 'HEART', 'PIC3', 'SCAT', 'SCAT', 'SCAT', 'HEART', 'PIC4', 'SPADE', 'DIAMOND', 'SPADE', 'PIC2', 'PIC5'],
        ['PIC5', 'WILD', 'WILD', 'WILD', 'CLUB', 'CLUB', 'PIC3', 'PIC2', 'PIC2', 'PIC1', 'DIAMOND', 'DIAMOND', 'PIC5', 'PIC5', 'PIC3', 'PIC1', 'DIAMOND', 'PIC4', 'PIC5', 'PIC5', 'PIC1', 'PIC4', 'DIAMOND', 'SCAT', 'SCAT', 'HEART', 'SPADE', 'PIC1', 'PIC5', 'PIC5', 'HEART', 'PIC2', 'PIC2', 'HEART', 'SCAT', 'SCAT', 'HEART', 'PIC1', 'PIC5', 'BONUS', 'SPADE', 'SPADE', 'DIAMOND', 'HEART', 'CLUB', 'HEART', 'PIC2', 'PIC1', 'PIC1', 'PIC1', 'HEART', 'SPADE', 'PIC2', 'HEART', 'HEART', 'PIC1', 'PIC5', 'BONUS', 'PIC4', 'PIC4', 'DIAMOND', 'PIC3', 'CLUB', 'DIAMOND'],
        ['PIC2', 'HEART', 'BONUS', 'SPADE', 'CLUB', 'HEART', 'PIC3', 'PIC2', 'HEART', 'PIC5', 'PIC3', 'PIC3', 'WILD', 'WILD', 'WILD', 'PIC3', 'PIC3', 'HEART', 'PIC3', 'PIC4', 'CLUB', 'CLUB', 'PIC4', 'PIC5', 'PIC4', 'SPADE', 'SCAT', 'HEART', 'SPADE', 'PIC1', 'CLUB', 'SPADE', 'HEART', 'PIC2', 'PIC2', 'PIC2', 'CLUB', 'BONUS', 'CLUB', 'SPADE', 'HEART', 'PIC1', 'PIC2', 'HEART', 'PIC5', 'SPADE', 'PIC1', 'PIC1', 'DIAMOND', 'PIC5', 'SPADE', 'SPADE', 'HEART', 'PIC3', 'PIC4', 'HEART', 'CLUB', 'PIC2', 'PIC5', 'SCAT', 'SPADE', 'HEART', 'PIC3', 'PIC4']
    ],

    reelsLayout: [3, 3, 3, 3, 3],

    playLines: [],

    coinAmountMultiplier: 20,
    validCoinAmounts: {
        SC: [1, 2, 5, 10, 25, 50, 100, 250],
        GC: [10, 20, 50, 100, 500, 1000, 2500],
    },

    winTable: [
        {
            symbol: 'PIC1',
            multipliers: [200, 60, 25],
        },
        {
            symbol: 'PIC2',
            multipliers: [100, 50, 20],
        },
        {
            symbol: 'PIC3',
            multipliers: [80, 40, 10],
        },
        {
            symbol: 'PIC4',
            multipliers: [60, 30, 10],
        },
        {
            symbol: 'PIC5',
            multipliers: [40, 20, 10],
        },
        {
            symbol: 'HEART',
            multipliers: [20, 10, 5],
        },
        {
            symbol: 'CLUB',
            multipliers: [20, 10, 5],
        },
        {
            symbol: 'DIAMOND',
            multipliers: [20, 10, 5],
        },
        {
            symbol: 'SPADE',
            multipliers: [20, 10, 5],
        },
    ],

    scatterWinTable: [
        {
            symbol: 'SCAT',
            multipliers: [0, 0, 1, 5, 15, 40, 100, 500, 2000], //ordered by 1 of a kind, 2 of a kind, ..., 9 of a kind,
        },
    ],
    bonusWinTable: [
        {
            symbol: 'BONUS',
            multipliers: [0, 0, 2, 10, 100], //ordered by 1 of a kind, 2 of a kind, ..., 5 of a kind
        },
    ],
    wildSymbol: 'WILD',
    scatterSymbol: 'SCAT',
    bonusSymbol: 'BONUS',

    freeSpinTriggerCount: [0, 0, 10, 20, 30], //ordered by number of free spins starting from 1 ... n
    freeSpinRetriggerCount: [0, 5, 10, 20, 30], //ordered by number of free spins starting from 1 ... n
    winLevels: [
        { name: 'normal1', threshold: 2 },
        { name: 'normal2', threshold: 5 },
        { name: 'normal3', threshold: 20 },
        { name: 'bigwin', threshold: 35 },
        { name: 'megawin', threshold: 70 },
        { name: 'epicwin' },
    ],
    superStarWeights: { chance: 1, outOf: 10 },
    superStarMultiplierWeights: [
        { outcome: 2, weight: 133 },
        { outcome: 3, weight: 62 },
        { outcome: 4, weight: 40 },
        { outcome: 5, weight: 20 },
        { outcome: 10, weight: 5 },
        { outcome: 20, weight: 1 },
    ],
    superStarProgressiveJackpotWeights: {
        SC: {
            20: { chance: 13781, outOf: 5000000 },
            40: { chance: 27562, outOf: 5000000 },
            100: { chance: 68905, outOf: 5000000 },
            200: { chance: 137810, outOf: 5000000 },
            500: { chance: 344525, outOf: 5000000 },
            1000: { chance: 689050, outOf: 5000000 },
            2000: { chance: 1378100, outOf: 5000000 },
            5000: { chance: 3445250, outOf: 5000000 },
        },
        GC: {
            200: { chance: 13781, outOf: 5000000 },
            400: { chance: 27562, outOf: 5000000 },
            1000: { chance: 68905, outOf: 5000000 },
            2000: { chance: 137810, outOf: 5000000 },
            5000: { chance: 344525, outOf: 5000000 },
            10000: { chance: 689050, outOf: 5000000 },
            20000: { chance: 1378100, outOf: 5000000 },
            50000: { chance: 3445250, outOf: 5000000 },
        },
    } as const,
    scatterSymbolsToTriggerProgressiveJackpots: 9,
};
