var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "sm-game-spec-content",
    },
    [
      _c("div", { staticClass: "flex-center menu" }, [
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addBaseStep()
              },
            },
          },
          [_vm._v("Add Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addFeatureTriggerStep()
              },
            },
          },
          [_vm._v("Feature Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreespinExtra5Step()
              },
            },
          },
          [_vm._v("FS Extra 5 Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreespinExtra15Step()
              },
            },
          },
          [_vm._v("FS Extra 15 Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.getFreespinExtra30Step()
              },
            },
          },
          [_vm._v("FS Extra 30 Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinTrigger()
              },
            },
          },
          [_vm._v("Respin Trigger")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addRespinStep()
              },
            },
          },
          [_vm._v("Respin Step")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button pointer",
            on: {
              click: function ($event) {
                return _vm.addClearingStep()
              },
            },
          },
          [_vm._v("Clearing Step")]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isRespin,
              expression: "!isRespin",
            },
          ],
          class: ["flex-center"],
        },
        _vm._l(_vm.reelsNo, function (reelIndex) {
          return _c("reel", {
            key: reelIndex,
            attrs: { index: reelIndex - 1, step: _vm.step },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespin || _vm.isRespinTrigger || _vm.isFeature,
              expression: "isRespin || isRespinTrigger || isFeature",
            },
          ],
          staticClass: "flex-center scatters-selector-wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "selected-scatters" },
            [
              _vm._l(5, function (value) {
                return _c("selectScatterMultiplier", {
                  key: value,
                  attrs: {
                    index: value,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectScatterMultiplier", {
                  key: value + 5,
                  attrs: {
                    index: value + 5,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(5, function (value) {
                return _c("selectScatterMultiplier", {
                  key: value + 5 + 5,
                  attrs: {
                    index: value + 5 + 5,
                    scattersSelectedMultipliers:
                      _vm.scattersSelectedMultipliers,
                  },
                })
              }),
              _vm._v(" "),
              _c("br"),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isRespin || _vm.isRespinTrigger,
              expression: "isRespin || isRespinTrigger",
            },
          ],
          staticClass: "flex-center menu",
        },
        [
          _c(
            "div",
            {
              staticClass: "button pointer",
              on: {
                click: function ($event) {
                  return _vm.resetRespinSymbols()
                },
              },
            },
            [_vm._v("Reset")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-center" }, [
      _c("h3", [_vm._v("Base Reels:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }